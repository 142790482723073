import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { firstValueFrom, take } from 'rxjs';
import { nswagCatchOperator } from '../shared/operators/nswag-catch-operator';
import {
  ActivePlanModules,
  ChangeTenantRequest,
  CompanyDto,
  CompanyOptionEnum,
  FunctionEnum,
  GoogleLoginRequest,
  HttpClientAuth,
  HttpClientPermission,
  LoginStep1Request,
  LoginStep2Request,
  ModuleEnum,
  PermissionsEnum,
  UserContractDto,
  UserResetPasswordTokenRequest,
} from '../shared/nswag.api';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { IGNORE_REDIS_CACHE } from '../shared/interceptor/cache.interceptor';
import { HttpContext } from '@angular/common/http';
import { SharedService } from '../shared/services/shared.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';

type AUTHORIZATION_STATE = {
  companyList: CompanyDto[] | undefined;
  userContracts: UserContractDto[];
  token: string | undefined;
  refreshToken: string | undefined;
  companyDto?: CompanyDto;
  step2Success?: boolean;
  step1Success?: boolean;
  loading: boolean;
  error: string | undefined;
  modules: ModuleEnum[];
  functions: FunctionEnum[];
  permissions: PermissionsEnum[];
  configurations: { [key in keyof typeof CompanyOptionEnum]?: boolean };
  activePlanModules: ActivePlanModules | undefined;
  isLoadingModulesFunctionsAndPermissions: boolean;
  loggedUserName: string | undefined;
  socialStep1HasError: boolean;
  step1HasError: boolean;
  isSwitchTenant: boolean;
};

export const INITIAL_AUTHORIZATION_STATE: AUTHORIZATION_STATE = {
  companyList: undefined,
  userContracts: [],
  token: undefined,
  refreshToken: undefined,
  companyDto: undefined,
  step2Success: false,
  step1Success: false,
  loading: false,
  error: undefined,
  modules: [],
  functions: [],
  permissions: [],
  configurations: [],
  activePlanModules: undefined,
  isLoadingModulesFunctionsAndPermissions: true,
  loggedUserName: undefined,
  socialStep1HasError: false,
  step1HasError: false,
  isSwitchTenant: false,
};

export const AuthorizationStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_AUTHORIZATION_STATE),
  withComputed(state => ({
    existingUsers: computed(() => state.activePlanModules()?.existingUsers),
    allowedUsers: computed(() => state.activePlanModules()?.userLimit),
    existingCompanies: computed(() => state.activePlanModules()?.existingCompanies),
    allowedCompanies: computed(() => state.activePlanModules()?.companiesLimit),
  })),
  withMethods(
    (
      store,
      httpClientAuth = inject(HttpClientAuth),
      httpClientPermission = inject(HttpClientPermission),
      toast = inject(ToastrService),
      // translate = inject(TranslateService),
      // injector = inject(Injector),
      sharedService = inject(SharedService),
      socialAuthService = inject(SocialAuthService),
      router = inject(Router),
      // oldStore = inject(Store),
    ) => ({
      authLoginStep1Request: async ({ contract, username, password, isGoogleLogin }) => {
        patchState(store, {
          loading: true,
        });
        const newData = await firstValueFrom(
          httpClientAuth
            .loginStep1(
              new LoginStep1Request({
                contractId: contract,
                username: username,
                passwordOrToken: password,
                isGoogleLogin: isGoogleLogin,
              }),
            )
            .pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) {
          patchState(store, {
            companyList: [],
            loggedUserName: undefined,
            error: newData.message ?? 'Unknown error',
            loading: false,
            step1HasError: true,
            step1Success: false,
          });
          if (newData?.message?.includes('ERR:10252')) {
            patchState(store, {
              loading: true,
            });
            const data = await firstValueFrom(
              httpClientAuth
                .requestToken(
                  new UserResetPasswordTokenRequest({
                    contract: contract,
                    userName: username,
                    password: password,
                  }),
                )
                .pipe(nswagCatchOperator(), take(1)),
            );
            patchState(store, {
              loading: false,
            });
            if (data.succeeded) {
              router.navigate(['/auth/reset-password'], {
                queryParams: { token: data.data },
              });
            } else toast.error(data.message);
          }
          return;
        }
        patchState(store, {
          companyList: newData.data.companies,
          loggedUserName: newData.data.name,
          loading: false,
          step1Success: true,
        });
      },

      authLoginStep2Request: async ({ company, username, password, isGoogleLogin }) => {
        patchState(store, {
          loading: true,
        });
        let bc: BroadcastChannel = new BroadcastChannel('relogin_channel');
        const newData = await firstValueFrom(
          httpClientAuth
            .loginStep2(
              new LoginStep2Request({
                companyDto: company,
                username: username,
                passwordOrToken: password,
                isGoogleLogin: isGoogleLogin,
              }),
            )
            .pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) {
          patchState(store, {
            error: newData.message ?? 'Unknown error',
            loading: false,
            socialStep1HasError: true,
          });
          return;
        }
        bc.postMessage('reload');
        localStorage.setItem('attemptedUsername', username);
        patchState(store, {
          companyDto: company,
          token: newData.data.key as string,
          refreshToken: newData.data.value as string,
          loading: false,
          step2Success: true,
          isLoadingModulesFunctionsAndPermissions: true,
        });
      },

      loginSocialStep1Request: async (token: string | undefined) => {
        const newData = await firstValueFrom(
          httpClientAuth.googleLoginStep1(new GoogleLoginRequest({ googleToken: token })).pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) {
          patchState(store, {
            userContracts: [],
            error: newData.message ?? 'Unknown error',
          });
          return;
        }

        patchState(store, {
          userContracts: newData.data ?? [],
        });
      },

      loginSocialStep1Failure: async ({ error }) => {
        if (error.includes('ERR:10252')) {
          patchState(store, {
            loading: true,
          });
          const newData = await firstValueFrom(
            httpClientAuth.requestToken(new UserResetPasswordTokenRequest({})).pipe(nswagCatchOperator(), take(1)),
          );

          if (newData.succeeded) {
            router.navigate(['/auth/reset-password'], {
              queryParams: { token: newData.data },
            });
          } else toast.error(newData.message);
        }
      },

      loadMFP: async (force_skip: boolean = false) => {
        let httpContext = force_skip ? new HttpContext().set(IGNORE_REDIS_CACHE, true) : undefined;
        const newData = await firstValueFrom(httpClientPermission.activePermissionsLoggedUser(httpContext).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) {
          patchState(store, {
            error: newData.message ?? 'Unknown error',
          });
          return;
        }

        if (store.isSwitchTenant()) router.navigate(['/dashboard']);

        patchState(store, {
          modules: newData.data.modules ?? [],
          functions: newData.data.functions ?? [],
          permissions: newData.data.permissions ?? [],
          configurations: newData.data.companyConfigurations ?? [],
          activePlanModules: newData.data.activePlanModules,
          isLoadingModulesFunctionsAndPermissions: false,
          isSwitchTenant: false,
          loading: false,
        });
      },

      onSwitchTenantRequest: async (company: CompanyDto | undefined) => {
        patchState(store, {
          loading: true,
          isSwitchTenant: true,
        });
        let bcSwitchTenant: BroadcastChannel = new BroadcastChannel('company_switch_channel');
        const newData = await firstValueFrom(
          httpClientAuth
            .changeTenant(
              new ChangeTenantRequest({
                companyDto: company,
              }),
            )
            .pipe(nswagCatchOperator(), take(1)),
        );

        if (!newData.succeeded || !newData.data) {
          patchState(store, {
            error: newData.message ?? 'Unknown error',
            isSwitchTenant: false,
          });
          return;
        }
        sharedService.clearCacheFunction();

        patchState(store, {
          companyDto: company,
          token: newData.data.key,
          refreshToken: newData.data.value,
          loading: false,
          modules: undefined,
          functions: undefined,
          permissions: undefined,
          activePlanModules: undefined,
          configurations: undefined,
        });
        bcSwitchTenant.postMessage('reload');
      },

      logout: async () => {
        localStorage.removeItem('attemptedUsername');
        socialAuthService.signOut(true);
        patchState(store, {
          token: undefined,
          refreshToken: undefined,
          companyDto: undefined,
          companyList: undefined,
          loggedUserName: undefined,
          userContracts: undefined,
          modules: [],
          functions: [],
          permissions: [],
          activePlanModules: undefined,
          isLoadingModulesFunctionsAndPermissions: false,
          loading: false,
          step1Success: false,
          step2Success: false,
        });
        sharedService.clearCacheFunction();
        router.navigate(['/auth/login']);
      },

      onRefreshTokenSuccess: async ({ token, refreshToken }) => {
        patchState(store, {
          token: token,
          refreshToken: refreshToken,
        });
      },

      updateCompanies: async (companies: CompanyDto[]) => {
        patchState(store, {
          companyList: companies,
        });
      },

      onLoginPageRefresh: async () => {
        patchState(store, {
          companyList: undefined,
          loading: false,
        });
      },

      clearAuthorizationCache: () => {
        patchState(store, INITIAL_AUTHORIZATION_STATE);
      },
    }),
  ),
  // withStorageSync({
  //   key: 'AUTHORIZATION_STATE',
  //   autoSync: true,
  //   select: state =>
  //     <Partial<AUTHORIZATION_STATE>>{
  //       companyDto: state.companyDto,
  //       token: state.token,
  //       refreshToken: state.refreshToken,
  //       companyList: state.companyList,
  //       loggedUserName: state.loggedUserName,
  //     },
  //   parse: (stateString: string) => {
  //     try {
  //       return JSON.parse(stateString) as AUTHORIZATION_STATE;
  //     } catch (e) {
  //       return INITIAL_AUTHORIZATION_STATE;
  //     }
  //   },
  //   stringify: state => JSON.stringify(state),
  //   storage: () => localStorage, // factory to select storage to sync with
  // }),
  withStorageSync({
    key: 'AUTHORIZATION_STATE',
    autoSync: true,
  }),
  withDevtools('devPosStore'),
);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

export type DraftType = {
  id?: string;
  jsonValue: any;
  createdDate?: string;
  updatedDate?: string;
  tenantIdentifier?: string;
};

const dbConfig: DBConfig = {
  name: 'devBooks',
  version: 1,
  objectStoresMeta: [
    {
      store: 'SaleRequest',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'jsonValue', keypath: 'jsonValue', options: { unique: false } },
        { name: 'createdDate', keypath: 'createdDate', options: { unique: false } },
        { name: 'updatedDate', keypath: 'updatedDate', options: { unique: false } },
        { name: 'tenantIdentifier', keypath: 'tenantIdentifier', options: { unique: false } },
      ],
    },
    {
      store: 'CashReceipt',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'jsonValue', keypath: 'jsonValue', options: { unique: false } },
        { name: 'createdDate', keypath: 'createdDate', options: { unique: false } },
        { name: 'updatedDate', keypath: 'updatedDate', options: { unique: false } },
        { name: 'tenantIdentifier', keypath: 'tenantIdentifier', options: { unique: false } },
      ],
    },
  ],
};

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxIndexedDBModule.forRoot(dbConfig)],
})
export class IndexdbModule {}

export const ApiPrioritySeconds = {
  lesser: 3600,
  lower: 600,
  low: 120,
  medium: 60,
  high: 30,
  critical: 15,
  once: 1,
};

export const EXPIRE_API_SECONDS = {
  COUNTRIES: ApiPrioritySeconds.high,
  SKIP_CACHE: ApiPrioritySeconds.low,
  CURRENCIES: ApiPrioritySeconds.critical,
  COSTINGCENTER: ApiPrioritySeconds.lower,
  COMPANYSTRUCUTRE: ApiPrioritySeconds.lower,
  API_VERSION: ApiPrioritySeconds.lesser,
  USERS: ApiPrioritySeconds.lesser,
  UNIT_OF_MEASURES: ApiPrioritySeconds.lesser,
  LOCATIONS: ApiPrioritySeconds.medium,
  ITEM_SCHEMES: ApiPrioritySeconds.medium,
  ITEM_GROUP: ApiPrioritySeconds.medium,
  ITEM_CATEGORY: ApiPrioritySeconds.medium,
  ITEM: ApiPrioritySeconds.lower,
  ITEM_DESCRIPTION_PAIR: ApiPrioritySeconds.lower,
  GL_ACCOUNTS: ApiPrioritySeconds.lower,
  COSTING_CENTER: ApiPrioritySeconds.lesser,
  VATS: ApiPrioritySeconds.lower,
  PARENTCATEGORY: ApiPrioritySeconds.medium,
  DECIMAL_PLACES: ApiPrioritySeconds.lesser,
  POINT_OF_SALE_NO: ApiPrioritySeconds.lesser,
  USER_OPTIONS: ApiPrioritySeconds.lower,
  INTEGRATION: ApiPrioritySeconds.lower,
  TRANSFER_SHIPMENT: ApiPrioritySeconds.lesser,
  TRANSFER_RECEIPT: ApiPrioritySeconds.lesser,
  ENDING_YEAR: ApiPrioritySeconds.lower,
  PAYMENT_RECEIVED: ApiPrioritySeconds.lower,
};
